import React from 'react'
import './styles/footer.css'

function Footer() {
    return (
        <div className = "footercontainer">
            <h4 className = "footertitle">Created By Jeremy</h4>
        </div>
    )
}

export default Footer
